import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getCampaignById } from '../api-calls/api-calls';

export const useGetCurrentCampaign = () => {
   let { campaignId } = useParams();

   return useQuery(
      ['campaign', campaignId],
      () => campaignId && getCampaignById(campaignId),
      {
         enabled: !!campaignId,
         staleTime: 10 * 60 * 1000,
      }
   );
};
