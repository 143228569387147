import { List} from "antd";
import React from "react";
import {ListDataFeedProps} from "../../types";
import DotItem from "./dot-item/dot-item";

const ListDataDots = ({ data }: ListDataFeedProps) => {
    return (
        <div className='h-full w-full'>
            <List
                pagination={{ defaultPageSize: 10, position: 'bottom', align: 'start' }}
                dataSource={data}
                renderItem={(value: string, index) =>  <DotItem data={value} />}
                className='flex justify-between flex-column'
                style={{height: '100%'}}
                grid={{ gutter: 10, column: data.length >= 10 ? 2 : 1 }}
            />
        </div>
    );
}

export default ListDataDots;