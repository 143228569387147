import React from 'react';
import { Statistic, theme } from 'antd';

type ScansTotal = {
   totalScans: number;
};

const { useToken } = theme;

const TotalScans = ({ totalScans }: ScansTotal) => {
   const { token } = useToken();

   return (
      <Statistic
         style={{ textAlign: 'center' }}
         title="TOTAL SCANS"
         value={totalScans}
         valueStyle={{
            fontSize: token.fontSizeHeading1,
            fontWeight: token.fontWeightStrong,
         }}
      />
   );
};

export default TotalScans;
