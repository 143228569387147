import { Layout, theme } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const PageHeader = ({ children }: { children: ReactNode }) => {
   const [target, setTarget] = useState<HTMLElement | DocumentFragment>(document.createDocumentFragment());

   useEffect(() => {
      setTarget(document.getElementById('header') as HTMLElement);
   }, [])

   const {
      token: { colorBgContainer },
   } = theme.useToken();

   return (
      ReactDOM.createPortal(<Layout.Header style={{ padding: '0 16px', background: colorBgContainer }}>{children}</Layout.Header>, target)
   )
}

export default PageHeader;