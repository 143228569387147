import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message, Steps, theme, Typography, Tag, Space, Badge, QRCode, Select, Card, Radio, RadioChangeEvent, Result, Descriptions, Modal, Empty, Collapse, Divider, Switch } from 'antd';
import { SwapOutlined, QrcodeOutlined, ArrowDownOutlined, DragOutlined, FormOutlined, GiftOutlined, ArrowRightOutlined, PlusOutlined, DownloadOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { api, defineBaseUrl } from '../../libs/api';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../components/page-header';

const CampaignDetailsStep = () => {
   return (
      <div style={{ width: '50%', margin: '0 auto' }}>
         <Form.Item
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            label="Campaign Name"
            name="name"
            rules={[{ required: true }]}
         >
            <Input />
         </Form.Item>
         <Form.Item
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            label="Description"
            name="description"
         >
            <Input />
         </Form.Item>
         <Form.Item label="Industry" name="industry" rules={[{ required: true }]}>
            <Select>
               <Select.Option value="hairCare">Haircare</Select.Option>
               <Select.Option value="skinCare">Skincare</Select.Option>
               <Select.Option value="bodyCare">Bodycare</Select.Option>
               <Select.Option value="sunScreen">Sunscreen</Select.Option>
               <Select.Option value="other">Other</Select.Option>
            </Select>
         </Form.Item>
         <Form.Item label="Product Category" name="productCategory" rules={[{ required: true }]}>
            <Select>
               <Select.Option value="shampoo">Shampoo</Select.Option>
               <Select.Option value="conditioner">Conditioner</Select.Option>
               <Select.Option value="mask">Mask</Select.Option>
               <Select.Option value="spray">Spray</Select.Option>
               <Select.Option value="oil">Oil</Select.Option>
               <Select.Option value="scalp">Scalp</Select.Option>
               <Select.Option value="hairSerum">Hair Serum</Select.Option>
               <Select.Option value="cleanser">Cleanser</Select.Option>
               <Select.Option value="moisturizer">Moisturizer</Select.Option>
               <Select.Option value="cream">Cream</Select.Option>
               <Select.Option value="serum">Serum</Select.Option>
               <Select.Option value="exfoliator">Exfoliator</Select.Option>
               <Select.Option value="eyeCream">Eye-cream</Select.Option>
               <Select.Option value="toner">Toner</Select.Option>
               <Select.Option value="spf">SPF</Select.Option>
               <Select.Option value="spraySpf">Spray SPF</Select.Option>
               <Select.Option value="oilSpf">Oil SPF</Select.Option>
               <Select.Option value="lotionSpf">Lotion SPF</Select.Option>
               <Select.Option value="spritzSpf">Spritz SPF</Select.Option>
               <Select.Option value="gel">Gel</Select.Option>
            </Select>
         </Form.Item>
         <Form.Item label="Distribution Channel" name="channel" rules={[{ required: true }]}>
            <Select>
               <Select.Option value="tradeShow">Tradeshow</Select.Option>
               <Select.Option value="giftWithPurchase">GWP</Select.Option>
               <Select.Option value="retail">Retail</Select.Option>
               <Select.Option value="ecommercePlatforms">eCommerce Platforms </Select.Option>
               <Select.Option value="brandEventsFieldMarketing">Brand Events - Field Marketing</Select.Option>
               <Select.Option value="socialMedia">Social Media</Select.Option>
               <Select.Option value="emailMarketing">Email Marketing</Select.Option>
               <Select.Option value="subscriptionBoxes">Subscription Boxes</Select.Option>
               <Select.Option value="distributors">Distributors</Select.Option>
               <Select.Option value="hairSalon">Hair Salon</Select.Option>
               <Select.Option value="skincarePracticeSpa">Skincare Practice/Spa</Select.Option>
            </Select>
         </Form.Item>
         <Form.Item
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            label="Number of Samples"
            name="volume"
            rules={[{ required: true }]}
         >
            <Input type="number" />
         </Form.Item>
         <Form.Item
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            label="Budget ($)"
            name="budget"
            rules={[{ required: true }]}
         >
            <Input type="number" />
         </Form.Item>
      </div>
   )
}

type CampaignObjectiveType = 'increaseBrandAwareness' | 'driveNewSales' | 'buildBrandLoyalty' | 'upsellExistingConsumers' | 'leadGeneration' | 'launchNewProduct' | 'businessDevelopment';

interface CampaignObjectiveState {
   label: string,
   id: CampaignObjectiveType
}

const campaignObjectives: CampaignObjectiveState[] = [
   { label: 'Increase brand awareness', id: 'increaseBrandAwareness' },
   { label: 'Drive new sales', id: 'driveNewSales' },
   { label: 'Build brand loyalty', id: 'buildBrandLoyalty' },
   { label: 'Upsell existing consumers', id: 'upsellExistingConsumers' },
   { label: 'Lead generation', id: 'leadGeneration' },
   { label: 'Launch new product', id: 'launchNewProduct' },
   { label: 'Business Development (B2B)', id: 'businessDevelopment' },
];

const getInitialObjectives = (objectives: string[]) => {
   if (!objectives) {
      return [];
   }

   return objectives.map(obj => {
      return campaignObjectives.find(o => o.id === obj);
   });
}

const CampaignObjectivesStep = () => {
   const { token } = theme.useToken();
   const form = Form.useFormInstance();
   const [items, setItems] = useState<any>(getInitialObjectives(form.getFieldValue("campaignObjectives")));

   const onDragEnd = (result: DropResult) => {
      if (!result.destination) {
         return;
      }

      const newItems = Array.from(items);
      const [removed] = newItems.splice(result.source.index, 1);
      newItems.splice(result.destination.index, 0, removed);

      form.setFieldValue("campaignObjectives", newItems.map((i: any, index) => i.id));
      setItems(newItems);
   };

   const handleChange = (tag: any, checked: boolean) => {
      const nextSelectedTags = checked
         ? [...items, tag]
         : items.filter((t: any) => t.id !== tag.id);

      form.setFieldValue("campaignObjectives", nextSelectedTags.map((i: any) => i.id));
      setItems(nextSelectedTags);
   };

   return (
      <>
         <Form.Item
            name="campaignObjectives"
            rules={[{ required: true, message: "Please select one or more campaign objectives" }]}
         >
            <>
               <div style={{ marginBottom: 32 }}>
                  <Typography.Title level={5}>Choose your campaign objectives (Up to 2 goals)</Typography.Title>
                  <ArrowDownOutlined style={{ display: 'block', marginBottom: 16 }} />
                  <Space size={[0, 8]} wrap align="center" style={{ padding: 32, border: `1px dashed ${token.colorPrimary}` }}>
                     {campaignObjectives.map((tag) => (
                        <Tag.CheckableTag
                           key={tag.id}
                           checked={!!items.find((t: any) => t.id === tag.id)}
                           onChange={(checked) => handleChange(tag, checked)}
                        >
                           {tag.label}
                        </Tag.CheckableTag>
                     ))}
                  </Space>
               </div>
               {
                  items.length > 0 ? (
                     <Space size={[0, 8]} wrap align="center" direction="vertical">
                        <DragDropContext onDragEnd={onDragEnd}>
                           <Typography.Title level={5}>Objective Priority <DragOutlined /></Typography.Title>
                           <Droppable droppableId="priorityCampaignObjectives">
                              {(provided, snapshot) => {
                                 return (
                                    <div {...provided.droppableProps}
                                       ref={provided.innerRef}
                                       style={{ background: snapshot.isDraggingOver ? token.colorBorderSecondary : token.colorBgBase, padding: 16, border: `1px dashed ${token.colorPrimary}`, minHeight: 300 }}>
                                       {items.map((item: any, index: any) => {
                                          return (
                                             <Draggable key={item.id} draggableId={item.id} index={index}>
                                                {(provided, snapshot) => {
                                                   return (
                                                      <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} style={{
                                                         userSelect: 'none',
                                                         ...provided.draggableProps.style,
                                                      }}>
                                                         <Badge count={index + 1} size="small" style={{ backgroundColor: token.colorPrimary }}>
                                                            <Tag color={snapshot.isDragging ? token.colorPrimaryActive : token.colorPrimary} style={{ width: '150px', marginBottom: 8 }}>
                                                               {item.label}
                                                            </Tag>
                                                         </Badge>
                                                      </div>
                                                   )
                                                }}
                                             </Draggable>
                                          )
                                       })}
                                       {provided.placeholder}
                                    </div>
                                 )
                              }}
                           </Droppable>
                        </DragDropContext>
                     </Space>
                  ) : null
               }
            </>
         </Form.Item>
      </>
   );
}

const CampaignQrStep = () => {
   const form = Form.useFormInstance();
   const [templates, setTemplates] = useState([]);
   const [selectedTemplate, setSelectedTemplate] = useState(form.getFieldValue('templateCx')?.id);
   const [CTAModalEdit, setCTAModalEdit] = useState<any>(null);

   useEffect(() => {
      const industry = form.getFieldValue('industry');
      const productCategory = form.getFieldValue('productCategory');
      const objectives = form.getFieldValue('campaignObjectives');
      const primaryObjective = objectives[0];
      const secondaryObjective = objectives[1];

      api.get('/template', {
         params: {
            industry,
            productCategory,
            primaryObjective,
            secondaryObjective
         }
      }).then(({ data }) => {
         setTemplates(data);

         if (data?.length > 0) {
            const defaultFirstTemplateSelection = data[0];
            form.setFieldValue('templateCx', defaultFirstTemplateSelection);
            setSelectedTemplate(defaultFirstTemplateSelection.id);
         }
      });
   }, []);

   return (
      <>
         <Space align="center" wrap style={{ justifyContent: 'center' }}>
            {
               templates.length > 0 ? (
                  templates.map((template: any, index) => (
                     <Card key={template.id} title={`Xcode - ${template.type}`} extra={<Radio value={template.id} checked={selectedTemplate === template.id} onChange={({ target: { value } }: RadioChangeEvent) => {
                        console.log(template);
                        form.setFieldValue('templateCx', template);
                        setSelectedTemplate(value);
                     }}></Radio>}>
                        <Space align="start" direction="vertical" style={{ justifyContent: 'center' }}>
                           {
                              template.variants.map((variant: any, index: number) => {
                                 return (
                                    <Space align="start">
                                       <div>
                                          <QRCode color="#5c54ff" size={128} value="http://wonka.cloud" style={{ marginBottom: 8 }} />
                                          <div style={{ marginTop: 8, width: '100%', height: 24, background: '#5c54ff', color: '#fff' }}>
                                             {variant.xcode.value}
                                          </div>
                                       </div>
                                       {
                                          template.id === selectedTemplate ? (
                                             <Button onClick={() => { setCTAModalEdit({ value: variant.xcode.value, position: index }) }}>Edit CTA</Button>
                                          ) : null
                                       }
                                    </Space>
                                 )
                              })
                           }
                        </Space>
                     </Card>
                  ))) : <Empty />
            }
         </ Space>
         <Modal
            title="Edit"
            centered
            open={!!CTAModalEdit}
            onOk={() => {
               const currentSelectedTempalte = form.getFieldValue('templateCx');
               currentSelectedTempalte.variants[CTAModalEdit.position].xcode.value = CTAModalEdit.value;
               form.setFieldValue('templateCx', currentSelectedTempalte);
               setCTAModalEdit(null);
            }}
            onCancel={() => {
               setCTAModalEdit(null);
            }}
         >
            <Input addonBefore="CTA Title" value={CTAModalEdit?.value} onChange={(event) => {
               const { value } = event.target;
               setCTAModalEdit({ ...CTAModalEdit, value });
            }} />
         </Modal>
      </>
   )
}

const translatePropertyKeys = {
   allowOtherOption: {
      label: '"Other" Option'
   },
   allowMultiOptions: {
      label: 'Multi Options'
   },
   allowCTA: {
      label: 'CTA Step'
   },
   retypeEmail: {
      label: 'Re-type Email'
   },
   sendAgain: {
      label: 'Send Again Email'
   },
   allowShare: {
      label: 'Share Option'
   },
   description: {
      label: 'Description'
   },
   redirectUrl: {
      label: 'Redirect Url'
   },
   layout: {
      label: `Layout ('grid' | 'list')`
   },
   buttonText: {
      label: 'Button Text'
   },
   shape: {
      label: 'Shape'
   }
} as any

const StepModal = ({ industry, open, onAddStep, onClose }: any) => {
   const [stepCatalog, setStepCatalog] = useState<any>(null);
   const [newStep, setNewStep] = useState<any>(null);

   useEffect(() => {
      api.get('catalog/steps', {
         params: {
            industry,
         }
      }).then(({ data }) => {
         setStepCatalog(data);
      }).catch(err => console.error(err));
   }, [industry]);

   return (
      <Modal
         title="Add new step"
         centered
         open={open}
         onOk={() => onAddStep(newStep)}
         onCancel={() => onClose()}
      >
         <Select style={{ width: 200 }} onSelect={(value: any) => {
            const step = stepCatalog.find((stepFromCatalog: any) => stepFromCatalog._id === value);
            console.log(step);
            setNewStep(step);
         }}>
            {
               stepCatalog && stepCatalog.map((step: any) => {
                  return (
                     <Select.Option key={step._id} value={step._id}>{step.label}</Select.Option>
                  )
               })
            }
         </Select>
         {

            newStep && (
               <>
                  <Divider />
                  {<Input placeholder="Add title..." addonBefore={<span>Title</span>} value={newStep.title} onChange={(event) => {
                     const { value } = event.target;
                     setNewStep(Object.assign({}, newStep, { title: value }));
                  }} />}
                  {
                     Object.entries(newStep?.properties).map(([key, value]: [string, any]) => {
                        {
                           if (typeof value === 'string') {
                              return (
                                 <div key={key} style={{ marginTop: 16 }}>
                                    <Input key={key} value={value} onChange={(event) => {
                                       const { value } = event.target;
                                       setNewStep(Object.assign({}, newStep, { properties: { ...newStep.properties, [key]: value } }));
                                    }} addonBefore={<span>{translatePropertyKeys[key]?.label}</span>} style={{ marginTop: 8 }} />
                                 </div>
                              )
                           }
                           if (typeof value === 'boolean') {
                              return (
                                 <div key={key} style={{ marginTop: 16 }}>
                                    <span style={{ marginRight: 8 }}>{translatePropertyKeys[key]?.label}</span>
                                    <Switch checked={value} onChange={(value) => {
                                       setNewStep(Object.assign({}, newStep, { properties: { ...newStep.properties, [key]: value } }));
                                    }} />
                                 </div>
                              )
                           }
                           if (Array.isArray(value)) {
                              return (
                                 <div key={key} style={{ marginTop: 16 }}>
                                    <Typography.Text>Options:</Typography.Text>
                                    {
                                       value.map((option: any, i: any, arr: any) => (
                                          <Input key={i} value={option.value} onChange={(event) => {
                                             const { value } = event.target;
                                             const updatedArray = Array.from(arr);
                                             updatedArray[i] = { value: value };
                                             setNewStep(Object.assign({}, newStep, { properties: { ...newStep.properties, [key]: updatedArray } }));
                                          }} addonBefore={<span>{i + 1}</span>} style={{ marginTop: 8 }} />
                                       ))
                                    }
                                 </div>
                              )
                           }
                        }
                     })
                  }
               </>
            )
         }
      </Modal>
   )
}


const StepEditModal = (({ step, open, onClose, onUpdateStep }: any) => {
   const [editedStep, setEditedStep] = useState(step);

   return (
      <Modal
         title="Edit step"
         centered
         open={open}
         onOk={() => onUpdateStep(editedStep)}
         onCancel={() => onClose()}
      >

         <>
            <Divider />
            {
               editedStep?.title && <Input placeholder="Add title..." addonBefore={<span>Title</span>} value={editedStep?.title} onChange={(event) => {
                  const { value } = event.target;
                  setEditedStep(Object.assign({}, editedStep, { title: value }));
               }} />
            }
            {
               editedStep?.properties && Object.entries(editedStep.properties).map(([key, value]: [string, any]) => {
                  {
                     if (typeof value === 'boolean') {
                        return (
                           <div key={key} style={{ marginTop: 16 }}>
                              <span style={{ marginRight: 8 }}>{translatePropertyKeys[key]?.label}</span>
                              <Switch checked={value} onChange={(value) => {
                                 setEditedStep(Object.assign({}, editedStep, { properties: { ...editedStep.properties, [key]: value } }));
                              }} />
                           </div>
                        )
                     }
                     if (Array.isArray(value)) {
                        return (
                           <div key={key} style={{ marginTop: 16 }}>
                              <Typography.Text>Options:</Typography.Text>
                              {
                                 value.map((option: any, i: any, arr: any) => (
                                    <Input key={i} value={option.value} onChange={(event) => {
                                       const { value } = event.target;
                                       const updatedArray = Array.from(arr);
                                       updatedArray[i] = { value: value };
                                       setEditedStep(Object.assign({}, editedStep, { properties: { ...editedStep.properties, [key]: updatedArray } }));
                                    }} addonBefore={<span>{i + 1}</span>} style={{ marginTop: 8 }} />
                                 ))
                              }
                           </div>
                        )
                     }
                  }
               })
            }
         </>
      </Modal>
   )
});


const CampaignXCodeStep = () => {
   const form = Form.useFormInstance();
   const { token } = theme.useToken();
   const selectedTemplateCx = form.getFieldValue('templateCx');
   const [items, setItems] = useState<any[]>(selectedTemplateCx.variants.map((v: any, i: any) => {
      return {
         id: `droppableId-variantSteps-${i}`,
         steps: v.steps.map((step: any, i: any) => {
            return {
               id: `${i * Math.random()}-${Date.now() * Math.random()}`,
               label: step.label,
               data: step
            }
         })
      };
   }));
   const [openAddStepModal, setOpenAddStepModal] = useState(false);
   const [editStepModal, setEditStepModal] = useState<any>(null);

   const openEditModal = !!editStepModal;

   const onDragEnd = (result: DropResult) => {
      if (!result.destination) {
         return;
      }

      const droppableZone = items.find(item => item.id === result.destination?.droppableId);

      const newSteps: any = Array.from(droppableZone.steps);
      const [removed] = newSteps.splice(result.source.index, 1);
      newSteps.splice(result.destination.index, 0, removed);

      const variantSteps = items.map(item => {
         if (item.id === result.destination?.droppableId) {
            return { ...item, steps: newSteps }
         }

         return item;
      });

      const newVariants = form.getFieldValue('templateCx').variants.map((v: any, index: number) => {
         return {
            ...v,
            steps: variantSteps[index].steps.map((step: any) => step.data)
         }
      });

      const template = form.getFieldValue('templateCx');

      template.variants = newVariants;

      form.setFieldValue('templateCx', template);

      console.log(template);

      setItems(variantSteps);
   };

   console.log('items', items);

   return (
      <div>
         {
            items.map((variant: any, i: any) => {
               return (
                  <div style={{ margin: '32px 0' }} key={i}>
                     <Collapse bordered accordion size="middle" defaultActiveKey={[`${variant.id}`]}>
                        <Collapse.Panel header={`samplCX ${i + 1}`} key={`${variant.id}`} extra={<Button icon={<PlusOutlined />} onClick={(event) => { event.stopPropagation(); setOpenAddStepModal(variant.id) }}>Add</Button>}>
                           <DragDropContext onDragEnd={onDragEnd}>
                              <Droppable droppableId={`${variant.id}`} direction="horizontal">
                                 {(provided, snapshot) => {
                                    return (
                                       <div {...provided.droppableProps}
                                          ref={provided.innerRef}
                                          style={{ background: snapshot.isDraggingOver ? token.colorBorderSecondary : token.colorBgBase, padding: 16, border: `1px dashed ${token.colorPrimary}`, display: 'flex', overflowX: 'auto', justifyContent: 'center', alignItems: 'center' }}>
                                          {variant.steps.map((step: any, index: any) => {
                                             return (
                                                <Draggable key={step.id} draggableId={step.id} index={index}>
                                                   {(provided, snapshot) => {
                                                      return (
                                                         <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} style={{
                                                            userSelect: 'none',
                                                            ...provided.draggableProps.style,
                                                         }}>
                                                            <Tag key={index} closable color={snapshot.isDragging ? token.colorPrimaryActive : token.colorPrimary} style={{ marginRight: 15 }}
                                                               onDoubleClick={() => {
                                                                  setEditStepModal({ step, id: variant.id });
                                                               }}
                                                               onClose={() => {
                                                                  console.log('droppableId', variant.id);

                                                                  const variantSteps = items.map(item => {
                                                                     if (item.id === variant.id) {
                                                                        const newSteps = Array.from(item.steps);

                                                                        newSteps.splice(index, 1);
                                                                        return { ...item, steps: newSteps }
                                                                     }

                                                                     return item;
                                                                  });

                                                                  const newVariants = form.getFieldValue('templateCx').variants.map((v: any, index: number) => {
                                                                     return {
                                                                        ...v,
                                                                        steps: variantSteps[index].steps.map((step: any) => step.data)
                                                                     }
                                                                  });

                                                                  const template = form.getFieldValue('templateCx');

                                                                  template.variants = newVariants;

                                                                  form.setFieldValue('templateCx', template);

                                                                  setItems(variantSteps);
                                                               }}>
                                                               {step.label}
                                                            </Tag>
                                                            {index !== variant.steps.length - 1 && !snapshot.isDragging ? (<ArrowRightOutlined style={{ marginRight: 15 }} />) : null}
                                                         </div>
                                                      )
                                                   }}
                                                </Draggable>
                                             )
                                          })}
                                          {provided.placeholder}
                                       </div>
                                    )
                                 }}
                              </Droppable>
                           </DragDropContext>
                        </Collapse.Panel>
                     </Collapse>
                  </div>
               )
            })
         }
         <StepModal industry={form.getFieldValue('industry')} open={openAddStepModal} onAddStep={(step: any) => {
            const variantSteps = items.map(item => {
               if (item.id === openAddStepModal) {
                  const newSteps = Array.from(item.steps);
                  newSteps.push({ id: Date.now().toString(), label: step.label, data: step });
                  return { ...item, steps: newSteps }
               }

               return item;
            });

            const newVariants = form.getFieldValue('templateCx').variants.map((v: any, index: number) => {
               return {
                  ...v,
                  steps: variantSteps[index].steps.map((step: any) => step.data)
               }
            });

            const template = form.getFieldValue('templateCx');

            template.variants = newVariants;

            form.setFieldValue('templateCx', template);

            console.log(template);

            setItems(variantSteps);

            setOpenAddStepModal(false);
         }} onClose={() => setOpenAddStepModal(false)} />
         {editStepModal && <StepEditModal step={editStepModal?.step?.data} open={openEditModal} onUpdateStep={(updatedStep: any) => {
            const variantSteps = items.map(item => {
               if (item.id === editStepModal.id) {
                  let newSteps = Array.from(item.steps);
                  newSteps = newSteps.map((step: any) => {
                     if (step.id === editStepModal.step.id) {
                        return { ...step, data: updatedStep }
                     }

                     return step;
                  });

                  return { ...item, steps: newSteps }
               }

               return item;
            });

            const newVariants = form.getFieldValue('templateCx').variants.map((v: any, index: number) => {
               return {
                  ...v,
                  steps: variantSteps[index].steps.map((step: any) => step.data)
               }
            });

            const template = form.getFieldValue('templateCx');

            template.variants = newVariants;

            form.setFieldValue('templateCx', template);

            console.log(template);

            setItems(variantSteps);

            setEditStepModal(null);
         }} onClose={() => setEditStepModal(null)} />}
      </div>
   );
}

const steps = [
   {
      content: <CampaignDetailsStep />,
   },
   {
      content: <CampaignObjectivesStep />,
   },
   {
      content: <CampaignQrStep />,
   },
   {
      content: <CampaignXCodeStep />,
   },
];

const items = [
   {
      title: 'Details',
      icon: <FormOutlined />
   },
   {
      title: 'Objectives',
      icon: <SwapOutlined />
   },
   {
      title: 'Xcode',
      icon: <GiftOutlined />,
   },
   {
      title: 'samplCX',
      icon: <QrcodeOutlined />,
   },
];

const CampaignBuilderPage: React.FC = () => {
   const [form] = Form.useForm();
   const [current, setCurrent] = useState(0);
   const [campaign, setCampaign] = useState<any>(null);
   const navigate = useNavigate();

   const handleNext = async () => {
      try {
         const values = await form.validateFields();

         console.log(values);

         setCurrent((current) => current + 1);
      } catch (error) {
         // handle validation errors
         console.error(error);
      }
   };

   const handlePrevious = () => {
      setCurrent((current) => current - 1);
   };

   const handleFinish = async () => {
      try {
         await form.validateFields();

         const values = await form.getFieldsValue(true);
         const primaryObjective = values.campaignObjectives[0];
         const secondaryObjective = values.campaignObjectives[1];

         const campaignPayload = Object.assign({}, values);

         delete campaignPayload.campaignObjectives;

         campaignPayload.primaryObjective = primaryObjective;
         campaignPayload.secondaryObjective = secondaryObjective;

         console.log('Creating campaign ->', campaignPayload);

         const { data } = await api.post('/campaign', campaignPayload);

         message.success(`Processing complete created draft campaign: ${data.campaignId}`);

         const { data: campaignData } = await api.get(`/campaign/${data.campaignId}`);

         setCampaign(campaignData);

      } catch (error) {
         // handle validation errors
         console.error(error);

         message.error('Processing in-complete we found an issue in your campaign.')
      }
   };

   const contentStyle: React.CSSProperties = {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      textAlign: 'center',
      backgroundColor: '#fff',
      // padding: 32
   };

   return (
      <>
         {campaign === null ? (
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
               <PageHeader>
                  <Steps
                     type="navigation"
                     current={current}
                     items={items}
                  />
               </PageHeader>
               <Form form={form} colon={false} onFinish={handleFinish} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>

                  <div style={contentStyle}>{steps[current].content}</div>

                  <div style={{ margin: '8px 0' }}>
                     {current > 0 && (
                        <Button style={{ marginRight: 8, width: 100 }} onClick={() => handlePrevious()}>
                           Previous
                        </Button>
                     )}
                     {current < items.length - 1 && (
                        <Button style={{ width: 100 }} type="primary" onClick={() => handleNext()}>
                           Next
                        </Button>
                     )}
                     {current === items.length - 1 && (
                        <Button type="primary" htmlType="submit">
                           Approve
                        </Button>
                     )}
                  </div>
               </Form>
            </div>
         ) : (
            <Result
               status="success"
               title="Successfully Created Wonka Campaign"
               extra={[
                  <Descriptions title="Campaign Info">
                     <Descriptions.Item label="Created By">{campaign.userId}</Descriptions.Item>
                     <Descriptions.Item label="Campaign Name">{campaign.name}</Descriptions.Item>
                     <Descriptions.Item label="Description">{campaign.description}</Descriptions.Item>
                     <Descriptions.Item label="Primary Objective">{campaign.primaryObjective}</Descriptions.Item>
                     <Descriptions.Item label="Secondary Objective">{campaign.secondaryObjective}</Descriptions.Item>
                     <Descriptions.Item label="Channel">
                        {campaign.channel}
                     </Descriptions.Item>
                  </Descriptions>,
                  <div>
                     <div style={{ marginBottom: 20 }}>
                        <Button icon={<DownloadOutlined />} onClick={() => {
                           let downloadTab = window.open(`${defineBaseUrl()}/campaign/${campaign._id}/download`, '_blank');

                           // Check every second if the download is complete
                           let downloadCheckInterval = setInterval(() => {
                              // If downloadTab is null, it means that the tab has been closed manually
                              if (downloadTab === null) {
                                 clearInterval(downloadCheckInterval);
                                 return;
                              }

                              // Try-catch block to handle errors when accessing downloadTab properties
                              try {
                                 // When the readyState of the document is 'complete', the download should be done
                                 if (downloadTab.document.readyState === 'complete') {
                                    downloadTab.close();
                                    downloadTab = null;
                                    clearInterval(downloadCheckInterval);
                                 }
                              } catch (e) {
                                 console.log('An error occurred while checking the download status:', e);
                              }
                           }, 1000);
                        }}>Download All</Button>
                     </div>
                     <Space>
                        {campaign.sampleCx.variants.map((variant: any) => {
                           return (
                              <div>
                                 <QRCode value={variant.xcode.url} />
                                 <div style={{ marginTop: '10px', marginBottom: '50px' }}>
                                    <Typography.Text strong >Scan me</Typography.Text>
                                 </div >
                              </div >
                           )
                        })}
                     </Space >
                  </div >
                  ,
                  <Space>
                     <Button type="primary" onClick={() => { navigate('/campaign') }}>
                        See All Campaigns
                     </Button>
                  </Space>
               ]}
            />
         )}
      </>
   )
};

export default CampaignBuilderPage;