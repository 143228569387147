import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider } from "antd";
import { AuthProvider } from "@descope/react-sdk";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'

// Create a client
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // TODO: Removed strict mode since react-beautiful-dnd not support strict mode in react 18, need to find a solution maybe change this package.
  // <React.StrictMode>
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: "#481da0",
        colorLink: "#5C55FF",
        colorLinkHover: "rgba(92, 85, 255,0.8)",
        fontSize: 14,
        fontSizeSM: 12,
        wireframe: true,
      },
      components: {
        Button: {
          colorPrimary: "#5C55FF",
          colorBorder: "#5C55FF",
          colorText: "#5C55FF",
          colorBgContainer: "transparent",
        },
        Menu: {
          colorItemText: "#fff",
          colorItemBg: "#481da0",
          colorItemBgSelectedHorizontal: "#fff",
          colorItemTextHover: "#fff",
        },
        Typography: {
          sizeMarginHeadingVerticalStart: 0,
          sizeMarginHeadingVerticalEnd: 0,
          fontSizeHeading1: 48,
        },
        Tag: {
          colorSuccess: "#00D1C4",
          borderRadiusSM: 19,
        },
        Divider: {
          colorSplit: "#D7D7D7",
        },
        Rate: {
          yellow6: "#5c54ff",
        },
        Cascader: {
          controlWidth: 124,
        },
        Select: {
          colorBorder: "#D7D7D7",
          // borderRadius: 0,
        },
      },
    }}
  >
    <AuthProvider projectId="P2QuoJ6N4CmXwmxv6PKTRrk1ySIa">
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools />
      </QueryClientProvider>
    </AuthProvider>
  </ConfigProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
