import React, { useState } from "react";
import style from "./campaign-details.module.scss";
import { ReactComponent as CalendarIcon } from "../../assets/calendar.svg";
import { ReactComponent as ShareBtnIcon } from "../../assets/share-bnt-icon.svg";
import { ReactComponent as ExportBtnIcon } from "../../assets/export-btn-icon.svg";
import { ReactComponent as TopBarItemIcon } from "../../assets/top-bar-item.svg";
import { ReactComponent as CampaignIcon } from "../../assets/campaign_icon.svg";
import { Button, Modal, Typography, Divider, Tag } from "antd";
import { useMediaQuery } from "react-responsive";
import { formatDate, maskVolume } from "../../utils";

const { Title, Text, Link } = Typography;

const CampaignDetails = ({ campaignInfo }: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  if (!campaignInfo) return <div />;

  const { name, description, state, createdAt, volume } = campaignInfo;

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={style["campaign-details"]}>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className="mr4">
            <CampaignIcon />
          </div>
          <div className="flex flex-column">
            <Title level={4}>{name}</Title>
            {!isTabletOrMobile ? (
              <div className="flex items-center">
                <Text type="secondary">{description}</Text>

                <Divider type="vertical" className="divider-vertical" />

                <Text type="secondary">
                  {maskVolume(volume)} samples for distribution
                </Text>

                <Divider type="vertical" className="divider-vertical" />

                <Text type="secondary" className="flex items-center">
                  <CalendarIcon />
                  <span className="ml1">
                    Started at: {formatDate(createdAt)}
                  </span>
                </Text>

                <Divider type="vertical" className="divider-vertical" />

                <Tag color="success">{state.toUpperCase()}</Tag>
              </div>
            ) : (
              <Link onClick={showModal}>Show details</Link>
            )}
          </div>
        </div>

        {!isTabletOrMobile && (
          <div className="top-bar-controllers align-self-baseline flex">
            <Button className="top-bar-controller flex items-center mr4">
              <ShareBtnIcon />
              <span>Share</span>
            </Button>
            <Button className="top-bar-controller flex items-center">
              <ExportBtnIcon />
              <span>Export</span>
            </Button>
          </div>
        )}

        <Modal
          closable={false}
          open={isModalOpen}
          onCancel={handleCancel}
          footer={[
            <div>
              <Text type="secondary" className="flex items-center">
                <TopBarItemIcon />
                <span className="ml2">{description}</span>
              </Text>

              <Text type="secondary" className="flex items-center mt2">
                <TopBarItemIcon />
                <span className="ml2">{volume} samples for distribution</span>
              </Text>

              <Text type="secondary" className="flex items-center flex mt2">
                <TopBarItemIcon />
                <span className="ml2">
                  <CalendarIcon />
                </span>
                <span className="ml2">
                  Campaign time: {formatDate(createdAt)} - 20 Mar 2023
                </span>
              </Text>
            </div>,
            <Link className="flex justify-end mt4 mb2" onClick={handleCancel}>
              Close
            </Link>,
          ]}
          className="campaign-top-bar-modal"
        >
          <div className="flex flex-column">
            <div className="flex">
              <div className="mr0">
                <CampaignIcon />
              </div>
              <div className="flex flex-column justify-between">
                <Title level={5}>{name}</Title>
                <Tag className="align-self-start" color="success">
                  {state.toUpperCase()}
                </Tag>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default CampaignDetails;
