import { useQuery } from '@tanstack/react-query';
import { Card, Empty } from 'antd';
import React from 'react';
import { componentRegistry } from '../../component-rigistry';
import { fetchResponseAnalyzerMetrics } from '../../api-calls/api-calls';
import { DataCardPropsMapperType, ViewDataCardType } from '../../types';

const ViewDataCard = ({
   viewData,
   campaignId,
   selectedFilters,
   startFrom,
   selectedDateRange,
   cardSize,
   onScansChange,
   totalScans,
}: ViewDataCardType) => {
   const { data, isLoading, isFetching } = useQuery(
      [selectedFilters, campaignId, viewData, startFrom],
      fetchResponseAnalyzerMetrics,
      {
         staleTime: 10 * 60 * 1000,
      }
   );

   const Component = (props: DataCardPropsMapperType) => {
      // Check if the desired component exists in the registry
      if (viewData.component in componentRegistry) {
         const ComponentConstructor = componentRegistry[viewData.component];
         return (
            <ComponentConstructor
               {...props}
               action={viewData.api.action}
               selectedDateRange={selectedDateRange}
               cardSize={cardSize}
               percentage={viewData.percentage}
            />
         );
      }

      return null; // Or render a fallback component/error message
   };

   const total = data?.[0]?.metadata ? `(${data[0]?.metadata?.total})` : '';

   return (
      <Card
         className="data-card"
         title={`${viewData.name || viewData.responseLabel} ${total}`}
         loading={isLoading || isFetching}
         bodyStyle={{ padding: 20, paddingTop: 0, height: '85%' }}
         headStyle={{ borderBottomWidth: 0 }}
      >
         {Component &&
            (data && data.length ? (
               <Component
                  data={data}
                  onScansChange={onScansChange}
                  totalScans={totalScans}
               />
            ) : (
               <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  className="flex flex-column"
               />
            ))}
      </Card>
   );
};

export default ViewDataCard;
