import { Pie } from '@ant-design/plots';
import { PieConfig } from '@ant-design/charts';
import React from 'react';
import { renderStatistic } from './pie-chart-helper';

type PieChartProps = {
   totalValue: number;
   data: {
      type: string;
      value: number;
   }[];
   percentage: boolean;
};

const PieChartDistribution = (props: PieChartProps) => {
   const { data, totalValue, percentage } = props;

   const config = {
      color: [
         '#5C55FF',
         '#946CE5',
         '#D186F4',
         '#E1BCFF',
         '#FFA5D6',
         '#84B5FF',
         '#88E2FF',
         '#49DCE5',
         '#EFF0F2',
      ],
      legend: {
         layout: 'horizontal',
         position: 'bottom',
      },
      appendPadding: 10,
      data,
      angleField: 'value',
      colorField: 'type',
      radius: 1,
      innerRadius: 0.64,
      meta: {
         value: {
            formatter: (v) => `${v}`,
         },
      },
      label: {
         type: 'inner',
         offset: '-50%',
         style: {
            textAlign: 'center',
         },
         autoRotate: false,
         content: percentage ? '{value}%' : '{value}',
      },
      statistic: {
         title: {
            offsetY: -4,
            customHtml: (container, view, datum) => {
               const { width, height } = container.getBoundingClientRect();
               const d = Math.sqrt(
                  Math.pow(width / 2, 2) + Math.pow(height / 2, 2)
               );
               const text = datum?.type || 'Total';
               return renderStatistic(d, text, {
                  fontSize: 28,
               });
            },
         },
         content: {
            offsetY: 4,
            style: {
               fontSize: '32px',
            },
            customHtml: (container, view, datum, data) => {
               if (!props.data) {
                  return;
               }

               const { width } = container.getBoundingClientRect();
               const text = datum ? `${datum.value}` : `${totalValue}`;
               return renderStatistic(width, text, {
                  fontSize: 32,
               });
            },
         },
      },
      interactions: [
         {
            type: 'element-selected',
         },
         {
            type: 'element-active',
         },
         {
            type: 'pie-statistic-active',
         },
      ],
   } as PieConfig;
   return (
      <div className="h-full w-full">
         <Pie {...config} />
      </div>
   );
};

export default PieChartDistribution;
