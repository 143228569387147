import React from 'react';
import { Area } from '@ant-design/charts';
import { AreaConfig } from '@ant-design/charts';
import { theme } from 'antd';

const { useToken } = theme;

type AreaChartDistributionProps = {
   data: {
      type: boolean;
      value: number;
   }[];
};

const AreaChart = ({ data }: AreaChartDistributionProps) => {
   const { token } = useToken();

   const config = {
      data: data,
      xField: 'type',
      yField: 'value',
      xAxis: {
         range: [0, 1],
         tickCount: 7,
      },
      point: {
         size: 5,
         shape: 'circle',
         style: {
            fill: 'white',
            stroke: token.colorLink,
            lineWidth: 2,
         },
      },
      areaStyle: () => {
         return {
            fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff',
         };
      },
   } as AreaConfig;

   return <Area className="w-full" {...config} />;
};

export default AreaChart;
