import React from 'react';
import { Liquid } from '@ant-design/plots';
import { LiquidConfig } from '@ant-design/charts';
import { theme } from 'antd';
import { ResponseAverageRatingSummaryProps } from '../../types';

const { useToken } = theme;

const AverageRatingLiquid = ({ data }: ResponseAverageRatingSummaryProps) => {
   const { token } = useToken();

   const rating = data.avg;

   const config: LiquidConfig = {
      percent: rating / 5,
      statistic: {
         content: {
            formatter() {
               return rating.toString();
            },
         },
      },
      shape: (x: number, y: number, width: number, height: number): any => {
         const path = [];
         const w = Math.min(width, height);

         for (let i = 0; i < 5; i++) {
            path.push([
               i === 0 ? 'M' : 'L',
               (Math.cos(((18 + i * 72) * Math.PI) / 180) * w) / 2 + x,
               (-Math.sin(((18 + i * 72) * Math.PI) / 180) * w) / 2 + y,
            ]);
            path.push([
               'L',
               (Math.cos(((54 + i * 72) * Math.PI) / 180) * w) / 4 + x,
               (-Math.sin(((54 + i * 72) * Math.PI) / 180) * w) / 4 + y,
            ]);
         }

         path.push(['Z']);
         return path;
      },
      outline: {
         border: 2,
         distance: 4,
         style: {
            stroke: token.colorLink,
            strokeOpacity: 0.65,
         },
      },
      wave: {
         length: 128,
      },
      theme: {
         styleSheet: {
            brandColor: token.colorLink,
         },
      },
   };

   return (
      <Liquid
         {...config}
         style={{
            color: data.avg / 5 > 0.5 ? token.colorBgBase : token.colorTextBase,
         }}
         className="rating-liquid"
      />
   );
};

export default AverageRatingLiquid;
