import React, { useState } from 'react';
import { Button, Space, Table, Tag, Tooltip, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../components/page-header';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../libs/api';
import { ColumnsType, TableRowSelection } from 'antd/es/table/interface';
import './index.css';


const columns: ColumnsType<any> = [
   {
      title: 'Campaign Name',
      dataIndex: 'name',
   },
   {
      title: 'Campaign Objectives',
      render: (_, record) => (
         <div>
            {record.primaryObjective && <Tag color="rgba(92, 85, 255, 0.15)" style={{ color: '#282421' }}>{record.primaryObjective}</Tag>}
            {record.secondaryObjective && <Tag color="rgba(92, 85, 255, 0.15)" style={{ color: '#282421' }}>{record.secondaryObjective}</Tag>}
         </div>
      )
   },
   {
      title: 'Sample',
      render: () => (
         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ marginRight: 8 }}>
               <svg width="26" height="50" viewBox="0 0 26 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.5 14.7766C0.5 13.1761 1.3501 11.696 2.73258 10.8896L4.79861 9.68441C5.48682 9.28295 6.26929 9.07141 7.06603 9.07141H17.981C18.751 9.07141 19.508 9.26895 20.1798 9.64513L22.4368 10.9091C23.8579 11.7049 24.7381 13.2066 24.7381 14.8354V46C24.7381 47.933 23.1711 49.5 21.2381 49.5H4C2.067 49.5 0.5 47.933 0.5 46V14.7766Z" fill="url(#paint0_linear_427_3004)" stroke="#7E7E7E" />
                  <rect x="4.30957" y="0.5" width="16.1429" height="6.61905" rx="3.30952" fill="url(#paint1_linear_427_3004)" stroke="#7E7E7E" />
                  <defs>
                     <linearGradient id="paint0_linear_427_3004" x1="12.619" y1="8.57141" x2="12.619" y2="50" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F8F7F7" />
                        <stop offset="1" stopColor="#CCCCCC" />
                     </linearGradient>
                     <linearGradient id="paint1_linear_427_3004" x1="12.381" y1="0" x2="12.381" y2="7.61905" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F8F7F7" />
                        <stop offset="1" stopColor="#CCCCCC" />
                     </linearGradient>
                  </defs>
               </svg>
            </div>
            <Typography.Text>Unitube</Typography.Text>
         </div>
      )
   },
   {
      title: 'Launch date',
      dataIndex: 'createdAt',
   },
   {
      title: 'Budget',
      dataIndex: 'budget',
      render: (budget) => (
         `$ ${budget}`
      )
   },
   {
      title: 'Samples Qty',
      dataIndex: 'volume',
   },
   {
      title: 'Preview Urls',
      render: ({ sampleCx }) => (
         sampleCx.variants.map(({ xcode }: any) => (
            <Tooltip title="View the campaign in a preview mode, your responses will not be counted not will have any affect on the campaign results.">
               <Button type="link" href={`${xcode.url}?preview=true`} target="_blank">
                  {xcode.value}
               </Button>
            </Tooltip>
         ))
      )
   }
];

const getAllCampaigns = async () => {
   console.log('Fetching all campaigns');

   try {
      const { data } = await api.get('/campaign');
      return data;
   } catch (reason) {
      console.error(`Error occured while fetching all campaigns`, reason);
   }
}

const transformCampaignDataToTablePresentation = (campaignsData: any[]) => {
   if (!campaignsData) {
      return [];
   }

   // TODO use campaign interface or type here, replace with any.
   return campaignsData.map((campaign: any) => ({
      key: campaign._id,
      ...campaign
   }));
}

const CampaignManagmentPage = () => {
   const { isLoading, data } = useQuery(['campaign'], getAllCampaigns);
   const campaignsViewData = transformCampaignDataToTablePresentation(data);
   const navigate = useNavigate();
   const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

   const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
      console.log('selectedRowKeys changed: ', newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
   };

   const rowSelection: TableRowSelection<any> = {
      selectedRowKeys,
      onChange: onSelectChange,
      selections: [
         Table.SELECTION_ALL,
         Table.SELECTION_NONE,
      ],
   };

   return (
      <>
         <PageHeader>
            <Space wrap align="center">
               <Button type="primary" onClick={() => {
                  navigate('new');
               }}>
                  Create New Campaign
               </Button>
            </Space>
         </PageHeader>
         <Table
            rowClassName="custom-row-style"
            className="custom-table-style"
            loading={isLoading}
            dataSource={campaignsViewData}
            rowSelection={rowSelection} columns={columns}
            onRow={(record, _) => {
               return {
                  onDoubleClick: () => navigate(`/campaign/${record._id}`), // double click row
               };
            }}
         />
      </>
   );
}

export default CampaignManagmentPage;