import axios from "axios";
import { getSessionToken } from "@descope/react-sdk";

function defineBaseUrl() {
   // Parse the URL of the current window location
   const url = new URL(window.location.href);

   // Try to retrieve the targetUrl from the URL parameters
   let targetUrl = url.searchParams.get('targetUrl');

   if (targetUrl) {
      return targetUrl;
   } else if (process.env.REACT_APP_API_URL) {
      return process.env.REACT_APP_API_URL;
   }
}

const api = axios.create({
   baseURL: defineBaseUrl(),
   timeout: 10000
});

api.interceptors.request.use(async (config) => {
   try {
      config.headers.Authorization = `Bearer ${getSessionToken()}`;

      return config;
   } catch (error) {
      console.error(error);
   }

   return config;
}, error => Promise.reject(error));

export { api, defineBaseUrl };