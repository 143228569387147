import React, { useCallback } from 'react';
import { Link, BrowserRouter, useLocation, Route, Routes } from 'react-router-dom';
import { Avatar, Button, Divider, Layout, Menu, Space, Typography, theme } from 'antd';
import CampaignManagmentPage from './pages/campaign-managment';
import CampaignOverviewPage from './pages/campaign-overview'
import CampaignBuilderPage from './pages/campaign-builder';
import { Descope, useDescope, useSession, useUser } from '@descope/react-sdk';
import './css/App.scss'

const { Content, Header } = Layout;
const { useToken } = theme;

const WonkaLogo = () => {
  return (
    <svg width="97" height="28" viewBox="0 0 97 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_78_14660)">
        <path d="M29.0303 25.4861C29.1805 25.4949 29.3263 25.5037 29.4676 25.5125C31.0137 25.6134 32.5553 25.723 34.1013 25.8064C36.1642 25.9161 37.9709 25.2492 39.486 23.876C40.82 22.6651 41.6063 21.1514 41.8404 19.357C41.889 18.9929 41.8846 18.6375 41.889 18.2733C41.889 18.1066 41.942 18.0452 42.1187 18.0584C42.4898 18.0935 42.8652 18.111 43.2363 18.1286C43.3953 18.1373 43.4616 18.1856 43.4616 18.3611C43.4483 20.7829 42.6267 22.88 40.9393 24.6306C39.9233 25.6836 38.7218 26.4601 37.3304 26.9164C35.7976 27.4166 34.2294 27.5175 32.6348 27.184C31.3715 26.9208 30.2274 26.4206 29.1849 25.6704C29.1363 25.6353 29.0877 25.5958 29.0435 25.5563C29.0347 25.5476 29.0347 25.5256 29.0259 25.4817" fill="white" />
        <path d="M40.1885 11.3895C40.0604 11.3807 39.9323 11.3719 39.8042 11.3675C38.2935 11.2754 36.7828 11.1745 35.2765 11.0867C31.4776 10.8586 28.1735 13.3945 27.449 17.1062C27.3474 17.6151 27.2988 18.1328 27.3342 18.6505C27.343 18.7953 27.3121 18.8436 27.1531 18.8304C26.7555 18.7953 26.3535 18.769 25.956 18.7558C25.8102 18.7514 25.7705 18.7076 25.7661 18.5584C25.7219 14.0175 29.1099 10.1479 33.6597 9.59067C36.0672 9.29671 38.2537 9.90217 40.1929 11.3939" fill="white" />
        <path d="M42.5737 14.0352C41.1911 13.9475 39.8659 13.8641 38.5363 13.7808C37.3834 13.7062 36.2305 13.6272 35.0731 13.5658C32.8203 13.4429 30.8458 14.7591 30.0948 16.8694C29.8696 17.5056 29.7856 18.1593 29.8431 18.8306C29.8563 18.9885 29.8077 18.9973 29.684 18.9885C29.2865 18.9578 28.8845 18.9271 28.487 18.9183C28.3058 18.9183 28.2705 18.8394 28.2705 18.6858C28.2793 16.0051 29.4499 14.0045 31.8264 12.7234C32.8822 12.153 34.0306 11.96 35.2277 12.0346C37.2067 12.1574 39.1856 12.2847 41.1646 12.4031C41.3147 12.4119 41.4252 12.4602 41.5224 12.5742C41.9067 13.0174 42.2512 13.4868 42.5737 14.044" fill="white" />
        <path d="M30.7396 23.1036C31.9102 23.1782 33.0808 23.2791 34.2558 23.3186C36.0669 23.3756 37.4981 22.6473 38.545 21.1688C39.1943 20.2475 39.424 19.2077 39.3798 18.1021C39.371 17.9397 39.424 17.8959 39.5609 17.9046C39.9673 17.9266 40.3781 17.9573 40.7845 17.9792C40.9215 17.988 40.9568 18.0626 40.9612 18.181C40.9745 20.6335 39.9806 22.5596 37.9045 23.9021C36.7074 24.6743 35.3778 24.9639 33.9554 24.8673C32.003 24.7357 30.0505 24.6173 28.0981 24.5032C27.9214 24.4944 27.7889 24.4374 27.674 24.3014C27.3472 23.9197 27.06 23.5073 26.7773 23.0948C26.7376 23.0378 26.6713 22.9588 26.7243 22.8843C26.7685 22.8272 26.8524 22.8579 26.9231 22.8623C28.1997 22.9413 29.4763 23.0203 30.7485 23.0992" fill="white" />
        <path d="M30.148 20.4669C31.4996 20.5502 32.8557 20.638 34.2119 20.7214C35.7181 20.8135 36.8004 19.7781 36.7695 18.282C36.7695 18.2293 36.7606 18.1767 36.7562 18.1284C36.712 17.8593 36.8313 17.7336 37.114 17.7511C37.4276 17.7731 37.7413 17.795 38.0505 17.8082C38.2095 17.8169 38.289 17.8564 38.3155 18.0451C38.5187 19.5412 37.6043 21.2083 36.2129 21.8708C35.5459 22.1911 34.8524 22.3227 34.1191 22.2745C31.5306 22.1078 28.9464 21.9454 26.3579 21.7919C26.1724 21.7787 26.0796 21.7173 26.0178 21.5462C25.8941 21.1864 25.7969 20.8179 25.6953 20.4537C25.6379 20.2431 25.6777 20.1817 25.9074 20.1993C27.0956 20.2826 28.2839 20.3528 29.4765 20.4274C29.7018 20.4406 29.9271 20.4581 30.1524 20.4713" fill="white" />
        <path d="M39.0929 16.4217C37.7545 16.3384 36.4161 16.2813 35.082 16.1629C33.3416 16.0137 32.1401 17.5098 32.52 18.9971C32.5509 19.1243 32.5333 19.1638 32.3919 19.155C31.959 19.1199 31.5261 19.0936 31.0888 19.0717C30.9696 19.0673 30.9298 19.0234 30.9165 18.9049C30.6206 16.6455 32.4979 14.522 34.7905 14.6273C37.5116 14.7501 40.2238 14.952 42.9404 15.1143C43.0773 15.1231 43.1348 15.1845 43.1789 15.2986C43.3203 15.689 43.4395 16.0795 43.5411 16.4831C43.5897 16.6718 43.5411 16.7025 43.3645 16.6893C41.9421 16.5928 40.5197 16.5095 39.0929 16.4173" fill="white" />
        <path d="M26.8924 8.21752C25.3817 14.5528 23.3674 20.9057 20.8452 27.2673C20.6729 27.7587 20.3725 28.0044 19.9573 28.0044H16.0259C15.6063 28.0044 15.3456 27.7587 15.2485 27.2673C14.1132 22.1342 13.4815 17.001 13.3579 11.8678H12.9515C11.5644 17.001 9.6871 22.1342 7.31502 27.2673C7.11624 27.7587 6.80703 28.0044 6.38739 28.0044H2.3014C1.88176 28.0044 1.65648 27.7587 1.63439 27.2673C0.591916 20.9013 0.0485901 14.5528 0 8.21752H3.18928C3.21136 13.7192 3.7326 19.2341 4.74858 24.7578H5.15497C7.55355 19.4535 9.4574 14.1492 10.8665 8.84052C11.0167 8.42373 11.2994 8.21313 11.7191 8.21313H15.244C15.6637 8.21313 15.9111 8.42373 15.9861 8.84052C16.1849 14.1975 16.8784 19.55 18.0623 24.9026H18.4687C20.7921 19.3263 22.6209 13.7631 23.9593 8.21313H26.8924V8.21752Z" fill="white" />
        <path d="M47.0484 28H43.8193L46.452 8.21753H49.3807L49.416 10.3893C51.099 8.94144 52.4684 8.21753 53.5329 8.21753H56.1303C60.2958 8.21753 62.0539 11.1263 61.2853 16.9483L59.8011 28H56.6118L58.096 16.9483C58.6172 13.0173 57.8354 11.0561 55.7593 11.0561H53.1619C52.0752 11.1044 50.6882 11.8283 49.0096 13.2279L47.044 28H47.0484Z" fill="white" />
        <path d="M69.1572 0L67.006 16.1366L75.5004 8.21749H79.7278L72.0859 15.4039C72.9251 19.629 75.1029 23.8057 78.6146 27.9298H75.1294C71.9622 24.4419 70.1467 20.9188 69.6784 17.3563L66.3787 20.3046L65.376 27.9298H62.147L65.8575 0H69.1572Z" fill="white" />
        <path d="M92.573 11.644L87.047 11.2754C84.3525 11.4948 82.6474 13.8684 81.9274 18.3873C81.3354 22.8317 81.8169 25.0561 83.3718 25.0561C85.8676 24.7621 88.4782 23.5819 91.1992 21.5199L92.573 11.644ZM96.1333 8.47632L93.536 28.0044H90.7928L91.0137 24.9815C88.1955 26.969 85.5098 27.9781 82.9654 28.0044C79.2549 28.0044 77.8325 24.7402 78.6983 18.2031C79.6613 11.5738 82.3824 8.11654 87.4534 8.0332L92.9043 8.40174L96.1333 8.47632Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_78_14660">
          <rect width="96.1333" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>

  )
}

const MainMenu = () => {
  const location = useLocation();
  const selectedKey = location.pathname.split('/')[1]; // get the first segment of the path

  return (
    <Menu mode="horizontal" theme="light" selectedKeys={[selectedKey]}>
      <Menu.Item key="home">
        <Link to="/home">Home</Link>
      </Menu.Item>
      <Menu.Item key="campaign">
        <Link to="/campaign">Campaigns</Link>
      </Menu.Item>
      <Menu.Item key="settings">
        <Link to="/settings">Settings</Link>
      </Menu.Item>
    </Menu>
  );
}

console.log(process.env);

const App: React.FC = () => {
  const { token } = useToken();
  const { isAuthenticated, isSessionLoading } = useSession();
  const { user, isUserLoading } = useUser();
  const { logout } = useDescope();

  console.log(user);

  const handleLogout = useCallback(() => {
    logout();
  }, [logout])

  return (
    <>
      {!isAuthenticated && (!isSessionLoading && !isUserLoading) &&
        (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#FFF', height: '100%', width: '100%', padding: 16 }}>
            <div style={{ maxWidth: 700 }}>
              <img style={{ width: '100%' }} src="/Login.png" />
            </div>
            <div>
              <Descope
                flowId="sign-in"
                onSuccess={(e) => console.log(e.detail.user)}
                onError={(e) => console.log('Could not log in!')}
              />
            </div>
          </div>
        )
      }
      {isAuthenticated &&
        (
          <BrowserRouter>
            <Layout className="layout" style={{ height: '100%' }}>
              <Header style={{ display: 'flex', alignItems: 'center', padding: '0 20px', backgroundColor: token.colorPrimary }}>
                <WonkaLogo />
                <Divider type="vertical" orientation="center" style={{ width: 1, background: 'white', height: '80%' }} />
                <div style={{flex: 1}}>
                  <MainMenu />
                </div>
                {user && (
                  <Space>
                    <Avatar src={user.picture} />
                    <Typography.Text style={{ color: 'white' }}>{user.name}</Typography.Text>
                    <Button onClick={handleLogout}>Logout</Button>
                  </Space>
                )}
              </Header>
              <div id="header"></div>
              <Content style={{ padding: '18px 20px', overflow: 'auto' }}>
                <Routes>
                  <Route
                    path="/campaign"
                    element={<CampaignManagmentPage />} />
                  <Route
                    path="/campaign/:campaignId"
                    element={<CampaignOverviewPage />} />
                  <Route
                    path="/campaign/new"
                    element={<CampaignBuilderPage />} />
                  <Route
                    path="*"
                    element={<CampaignManagmentPage />} />
                  <Route />
                </Routes>
              </Content>
            </Layout >
          </BrowserRouter >
        )
      }
    </>
  );
};

export default App;