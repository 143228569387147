import { JSXElementConstructor } from 'react';
import AverageRatingSummary from './components/average-rating-summary/average-rating-summary';
import PieChartDistribution from './components/pie-chart-distribution/pie-chart-distribution';
import ListDataDots from './components/list-data-dots/list-data-dots';
import ListDataRows from './components/list-data-rows/list-data-rows';
import ScansOverview from './components/scans/scans-overview';
import { dataCardPropsMapper } from './utils';
import BarChartDistribution from './components/bar-chart-distribution/bar-chart-distribution';
import AreaChart from './components/area-chart/area-chart';
import ConversionScans from './components/scans/scans-conversion';
import TotalScans from './components/scans/scans-total';
import AverageRatingLiquid from './components/average-rating-liquid/average-rating-liquid';
import JourneyAnalysis from './components/journey-analysis/journey-analysis';

type ComponentRegistry = {
   [key: string]: JSXElementConstructor<any>;
};
export const componentRegistry: ComponentRegistry = {
   rating: (props) => AverageRatingSummary(dataCardPropsMapper(props)),
   'rating-liquid': (props) => AverageRatingLiquid(dataCardPropsMapper(props)),
   'rating-bar': (props) => BarChartDistribution(dataCardPropsMapper(props)),
   pie: (props) => PieChartDistribution(dataCardPropsMapper(props)),
   listDots: (props) => ListDataDots(dataCardPropsMapper(props)),
   listRows: (props) => ListDataRows(dataCardPropsMapper(props)),
   column: (props) => ScansOverview(dataCardPropsMapper(props)),
   bar: (props) => BarChartDistribution(dataCardPropsMapper(props)),
   area: (props) => AreaChart(dataCardPropsMapper(props)),
   'scans-conversion': (props) => ConversionScans(dataCardPropsMapper(props)),
   'scans-total': (props) => TotalScans(dataCardPropsMapper(props)),
   'journey-analysis': (props) => JourneyAnalysis(dataCardPropsMapper(props)),
};
