import React, {useState} from "react";
import {List, Modal, theme, Typography} from "antd";


const { Text } = Typography;

const { useToken } = theme;


const RawItem = ({data}: any) => { //TODO: adjust data interface when API ready


    const { token } = useToken();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <List.Item className='raw-item flex flex-column items-start p3'>
            <Text className='pointer' onClick={showModal}>
                <div className='text-container'>
                    {data}
                </div>
            </Text>

            <Modal
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
                title='Review'
                centered={true}
                wrapClassName='review-modal-wrap'
                style={
                    {
                        borderTopStyle: 'solid',
                        borderWidth: 8,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        borderColor: token.colorPrimary
                    }
                }
            >
                <div>
                    {data}
                </div>
            </Modal>
        </List.Item>
    )
}

export default RawItem;