import React, { useState } from 'react';
import { Button, Tabs, Skeleton, Divider } from 'antd';
import { useParams } from 'react-router-dom';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import CampaignDetails from '../../components/campaign-details/campaign-details';
import ViewDataCard from '../../components/view-data-card/view-data-card';
import { useGetCurrentCampaign } from '../../hooks/hooks';
import CampaignFilter from '../../components/campaign-filter/campaign-filter';
import { Filter, SingleOption, ViewData, ViewResponse } from '../../types';
import DateFilter from '../../components/date-filter/date-filter';
import { lastMonthRange } from '../../utils';

const ResponsiveGridLayout = WidthProvider(Responsive);

const CampaignOverviewPage = () => {
   const { campaignId } = useParams();

   const {
      data: campaign,
      error,
      isLoading,
      isSuccess,
   } = useGetCurrentCampaign();

   const [isEditMode, setIsEditMode] = useState(false);

   const [selectedFilters, setSelectedFilters] = useState<Filter[][]>([]);

   const [selectedDateFilter, setSelectedDateFilter] =
      useState<string>(lastMonthRange);

   const [selectedDateRange, setSelectedDateRange] =
      useState<string>('last30Days');

   const [totalScans, setTotalScans] = useState<number>(0);

   const onScansChange = (scans: number) => {
      setTotalScans(scans);
   };

   // Get the layout data from each view
   const layout =
      campaign &&
      campaign.views.flatMap((view: ViewResponse) =>
         view.viewData.map((viewd: ViewData) => viewd.layout)
      );

   const handleOnChange = (e: Filter[][]) => {
      setSelectedFilters(e);
   };

   const onDateFilterChange = (value: string, option: SingleOption) => {
      setSelectedDateFilter(value);
      setSelectedDateRange(option.id);
   };

   return (
      <div>
         {isLoading ? (
            <Skeleton paragraph={{ rows: 1 }} />
         ) : (
            <CampaignDetails campaignInfo={campaign} />
         )}

         <Tabs
            items={
               campaign &&
               campaign.views.map((view: ViewResponse) => {
                  return {
                     key: view.id,
                     label: view.label,
                     children: (
                        <>
                           <div
                              style={{
                                 display: 'flex',
                                 alignItems: 'center',
                                 justifyContent: 'space-between',
                              }}
                           >
                              <div className="flex items-center">
                                 <DateFilter
                                    onDateFilterChange={onDateFilterChange}
                                    selectedDateFilter={selectedDateFilter}
                                    campaignCreationDate={campaign?.createdAt}
                                 />

                                 <Divider
                                    type="vertical"
                                    className="mr3 ml3 divider-vertical"
                                 />

                                 <CampaignFilter
                                    onFilterChange={handleOnChange}
                                 />
                              </div>

                              <Button
                                 type="primary"
                                 onClick={() => setIsEditMode(!isEditMode)}
                              >
                                 {isEditMode ? 'Stop Edit' : 'Edit'}
                              </Button>
                           </div>
                           {isSuccess && campaignId && (
                              <ResponsiveGridLayout
                                 className="layout"
                                 layouts={{ lg: layout }}
                                 autoSize
                                 isResizable={isEditMode}
                                 isDraggable={isEditMode}
                                 isBounded
                                 useCSSTransforms
                                 rowHeight={210}
                              >
                                 {view.viewData.map((data: ViewData) => (
                                    <div key={data.layout.i}>
                                       <ViewDataCard
                                          key={data.id}
                                          viewData={data}
                                          campaignId={campaignId}
                                          selectedFilters={selectedFilters}
                                          startFrom={
                                             selectedDateFilter ||
                                             campaign?.createdAt
                                          }
                                          selectedDateRange={selectedDateRange}
                                          cardSize={
                                             data.layout.w === 6
                                                ? 'big'
                                                : 'small'
                                          }
                                          onScansChange={onScansChange}
                                          totalScans={totalScans}
                                       />
                                    </div>
                                 ))}
                              </ResponsiveGridLayout>
                           )}
                        </>
                     ),
                  };
               })
            }
         />
      </div>
   );
};

export default CampaignOverviewPage;
