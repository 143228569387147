import React from 'react';
import { Gauge } from '@ant-design/plots';
import { GaugeConfig } from '@ant-design/charts';
import { useGetCurrentCampaign } from '../../hooks/hooks';
import { maskConversion } from '../../utils';
import { theme } from 'antd';

type ScansConversion = {
   totalScans: number;
};

const { useToken } = theme;

const ConversionScans = ({ totalScans }: ScansConversion) => {
   const { token } = useToken();

   console.log(token);

   const { data: campaign } = useGetCurrentCampaign();

   const conversions = ((totalScans / campaign?.volume) * 100).toFixed();

   const config: GaugeConfig = {
      percent: totalScans / campaign?.volume,
      range: {
         color: `l(0) 0:${token.colorLink} 1:${token.purple5}`,
      },
      startAngle: Math.PI,
      endAngle: 2 * Math.PI,
      indicator: false,
      statistic: {
         title: {
            offsetY: -36,
            style: {
               fontSize: `${token.fontSizeHeading1}px`,
               color: token.colorLink,
            },
            formatter: () => `${maskConversion(parseInt(conversions))}%`,
         },
         content: {
            style: {
               fontSize: `${token.fontSizeHeading3}px`,
               color: '#4B535E',
            },
            formatter: () => 'CONVERSION SCANS',
         },
      },
   };

   return <Gauge {...config} />;
};

export default ConversionScans;
