import {
   DataCardPropsMapperType,
   PieChartDataResponse,
   ViewData,
} from './types';

interface Item {
   type: string;
   value: number;
}

interface DataItem {
   formattedDate: string;
   count: number;
}

interface JourneyAnalysisItem {
   label: string;
   total: number;
}

export const dataCardPropsMapper = (props: DataCardPropsMapperType) => {
   switch (props.action) {
      case 'list':
         return {
            ...props.data[0],
         };
      case 'avg':
         return {
            data: { ...props.data[0] },
         };
      case 'distribution':
         const totalValue = props.data.reduce(
            (total: number, d: PieChartDataResponse) => total + d.count,
            0
         );
         const datums = props.data.map((d: PieChartDataResponse) => ({
            type: String(d._id || 'N/A'),
            value: props.percentage
               ? Math.round((d.count / totalValue) * 100)
               : d.count,
         }));

         return {
            data: datums.sort((a: Item, b: Item) =>
               b.type.localeCompare(a.type)
            ),
            totalValue,
            percentage: !!props.percentage,
         };

      case 'count':
         const selectedDateRange = props.selectedDateRange;

         let days;

         switch (selectedDateRange) {
            case 'last7Days':
               days = generateDateArray(7);
               break;
            case 'last30Days':
               days = generateDateArray(30);
               break;
            default:
               days = generateDateArray(183);
         }

         const totalScans = days.reduce((total, day) => {
            const dataItem = props.data.find(
               (item: DataItem) => item.formattedDate === day.type
            );
            if (dataItem) {
               day.value = dataItem.count;
               total += dataItem.count;
            }
            return total;
         }, 0);

         return {
            data: days,
            totalScans,
            selectedDateRange,
            cardSize: props.cardSize,
            onScansChange: props.onScansChange,
         };
      case 'analysis':
         return [
            { stage: 'Scans', number: props.totalScans },
            ...props.data[0].labelTotals.map((item: JourneyAnalysisItem) => ({
               stage: item.label,
               number: item.total,
            })),
         ];
      default:
         return props;
   }
};

const scientificNotationToDecimal = (num: number) => {
   // Convert the number to a string to check if it is in scientific notation.
   const numStr = num.toString();

   // Check if the number is in scientific notation (contains 'e' character).
   if (numStr.includes('e')) {
      // Convert the number to a decimal string using Number.prototype.toFixed().
      const decimalStr = num.toFixed(20);

      // Remove any trailing zeros from the decimal string.
      const trimmedDecimalStr = decimalStr.replace(/\.?0+$/, '');

      return trimmedDecimalStr;
   }

   // If the number is not in scientific notation, return it as is.
   return num;
};

export const maskVolume = (n: number) => {
   if (n >= 1000000) {
      return n % 1000000 === 0
         ? `${n / 1000000}M`
         : `${(n / 1000000).toFixed(1).replace(/\.0$/, '')}m`;
   } else if (n >= 1000) {
      return n % 1000 === 0
         ? `${n / 1000}K`
         : `${(n / 1000).toFixed(1).replace(/\.0$/, '')}k`;
   } else {
      return `${n}`;
   }
};

export const maskConversion = (num: number) => {
   const newNum = scientificNotationToDecimal(num);

   if (Number.isInteger(newNum)) {
      return newNum; // If the number is an integer, return it as is.
   }

   // Convert the number to a string to access its individual digits.
   const numStr = newNum.toString();

   // Find the position of the decimal point in the number.
   const decimalPosition = numStr.indexOf('.');

   // If there is no decimal point, return the number as is.
   if (decimalPosition === -1) {
      return newNum;
   }

   // Find the first non-zero digit after the decimal point.
   let nonZeroDigitIndex = decimalPosition + 1;
   while (
      nonZeroDigitIndex < numStr.length &&
      numStr.charAt(nonZeroDigitIndex) === '0'
   ) {
      nonZeroDigitIndex++;
   }

   // If all digits after the decimal point are zeros, return the number as is.
   if (nonZeroDigitIndex === numStr.length) {
      return newNum;
   }

   // Truncate the number to the first non-zero digit after the decimal point.
   const truncatedNum = numStr.substring(0, nonZeroDigitIndex + 1);

   return truncatedNum;
};

export const getCardDataApiUrl = (data: ViewData, campaignId: string) => {
   return data.api.endpoint.replace(':campaignId', campaignId);
};

export const formatDate = (dateString: string) => {
   const date = new Date(dateString);

   // Options for formatting the date
   const options: any = { day: '2-digit', month: 'short', year: 'numeric' };

   // Format the date using the options
   const formattedDate = date.toLocaleDateString('en-US', options);

   return formattedDate;
};
export const getCurrentDate = (): string => {
   const currentDate: Date = new Date();

   // Define month names
   const monthNames: string[] = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
   ];

   const day: number = currentDate.getDate();
   const month: string = monthNames[currentDate.getMonth()];
   const year: number = currentDate.getFullYear();

   const formattedDate: string = `${day} ${month} ${year}`;

   return formattedDate;
};

export const generateDateArray = (numberOfDays: number) => {
   const result = [];
   const today = new Date();

   for (let i = numberOfDays - 1; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);

      const formattedDate = `${date.getDate()} ${date.toLocaleString(
         'default',
         {
            month: 'short',
         }
      )}`;

      result.push({ type: formattedDate, value: 0 });
   }

   return result;
};

export const getStartDate = (numberOfDays: number): string => {
   const today = new Date();
   const pastDate = new Date(today);
   pastDate.setDate(pastDate.getDate() - numberOfDays);

   const formattedDate = pastDate.toISOString();

   return formattedDate;
};
export const lastWeekRange = getStartDate(7);

export const lastMonthRange = getStartDate(30);

export const last6MonthRange = getStartDate(183);

export const getMaxValue = (dataArray: Item[]): number => {
   return dataArray.reduce((max, item) => {
      return item.value > max ? item.value : max;
   }, 0);
};
