import React from 'react';
import { Bar } from '@ant-design/plots';
import { PieChartData } from '../../types';
import { BarConfig } from '@ant-design/charts';

type BarChartDistributionProps = {
   data: PieChartData[];
};

const BarChartDistribution = (props: BarChartDistributionProps) => {
   const config = {
      data: props.data,
      xField: 'value',
      yField: 'type',
      seriesField: 'type',
      legend: {
         position: 'bottom',
      },
      xAxis: {
         // tickInterval: 1,
      },
      color: [
         '#5C55FF',
         '#946CE5',
         '#D186F4',
         '#E1BCFF',
         '#FFA5D6',
         '#84B5FF',
         '#88E2FF',
         '#49DCE5',
         '#EFF0F2',
      ],
   } as BarConfig;

   return <Bar {...config} className="h-full w-full" />;
};

export default BarChartDistribution;
