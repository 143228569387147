import { List} from "antd";
import React from "react";
import style from './list-data-rows.module.scss'
import RawItem from "./raw-item/raw-item";
import {ListDataFeedProps} from "../../types";

const ListDataRows = ({ data }: ListDataFeedProps) => {

    return (
        <div className={`${style['list-data-rows']} h-full w-full`}>
            <List
                pagination={{ defaultPageSize: 3, position: 'bottom', align: 'start' }}
                dataSource={data}
                renderItem={(value: string, index) =>  <RawItem data={value} />}
                className='flex justify-between flex-column'
                style={{height: '100%'}}
            />
        </div>

    );
}

export default ListDataRows;