import React, { useEffect } from 'react';
import { useGetCurrentCampaign } from '../../hooks/hooks';
import { Divider, theme, Typography } from 'antd';
import { Column } from '@ant-design/plots';
import { ColumnConfig } from '@ant-design/charts';
import { getMaxValue, maskConversion, maskVolume } from '../../utils';

const { Title, Text } = Typography;

const { useToken } = theme;

type ScansProps = {
   data: { type: string; value: number }[];
   totalScans: number;
   selectedDateRange: string;
   cardSize: string;
   onScansChange: (scans: number) => void;
};
const ScansOverview = ({
   data,
   totalScans,
   selectedDateRange,
   cardSize,
   onScansChange,
}: ScansProps) => {
   const { token } = useToken();

   useEffect(() => {
      onScansChange(totalScans);
   }, []);

   const { data: campaign, error, isLoading } = useGetCurrentCampaign();

   const setAxisXTickInterval = () => {
      switch (selectedDateRange) {
         case 'last7Days':
            return 1;
         case 'last30Days':
            return cardSize === 'small' ? 4 : 1;
         case 'last183Days':
            return 14;
         default:
            return 2;
      }
   };
   const setAxisYTickInterval = (maxValue: number): number => {
      if (maxValue > 100) {
         return 20;
      } else if (maxValue > 50) {
         return 14;
      } else {
         return 1;
      }
   };

   const config = {
      data: data,
      xField: 'type',
      yField: 'value',
      xAxis: {
         tickInterval: setAxisXTickInterval(),
         label: {
            autoHide: false,
            autoRotate: true,
         },
      },
      yAxis: {
         tickInterval: setAxisYTickInterval(getMaxValue(data)),
         label: {
            formatter: (v) => `${maskVolume(parseInt(v))}`,
         },
      },
      meta: {
         type: {
            alias: 'ScansOverview',
         },
         value: {
            alias: 'ScansOverview',
         },
      },
   } as ColumnConfig;

   const conversions = ((totalScans / campaign?.volume) * 100).toFixed();

   return (
      <div className="flex w-full h-full">
         <div className="scans flex flex-column mr4">
            <Title level={1} style={{ color: token.colorLink }}>
               {maskVolume(totalScans)}
            </Title>
            <Text type="secondary">TOTAL SCANS</Text>

            <Divider />

            <Title level={1} style={{ color: token.colorLink }}>
               {maskConversion(parseInt(conversions))}%
            </Title>
            <Text type="secondary">CONVERSION SCANS</Text>
         </div>
         <Column {...config} className="flex-auto" />
      </div>
   );
};
export default ScansOverview;
