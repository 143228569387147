import React from "react";
import {List, Typography, Popover} from "antd";
import {ReactComponent as BulletItemIcon} from '../../../assets/bullet.svg';
import style from './dot-item.module.scss'

const { Text } = Typography;

const DotItem = ({data}: any) => {

    return (
        <List.Item className={`${style['dot-item']} mt4`}>
            <Popover content={data}>
                <Text className='flex items-center'>
                    <div className='flex items-center justify-center'>
                        <BulletItemIcon />
                    </div>
                    <span className='ml2'>{data}</span>
                </Text>
            </Popover>
        </List.Item>
    )
}

export default DotItem;