import React from 'react';
import { Select, Typography } from 'antd';
import style from './date-filter.module.scss';
import {
   formatDate,
   getCurrentDate,
   last6MonthRange,
   lastMonthRange,
   lastWeekRange,
} from '../../utils';
import { SingleOption } from '../../types';

const { Text } = Typography;

type DateFilterProps = {
   onDateFilterChange: (value: string, option: SingleOption | any) => void;
   campaignCreationDate: string;
   selectedDateFilter: string;
};

const DateFilter = ({
   onDateFilterChange,
   selectedDateFilter,
   campaignCreationDate,
}: DateFilterProps) => {
   return (
      <div className={`${style['date-filter']} flex items-center`}>
         <Text className="mr3">Showing results:</Text>

         <Select
            className="select-date-button"
            defaultValue={lastMonthRange}
            bordered={false}
            onChange={onDateFilterChange}
            options={[
               {
                  value: lastWeekRange,
                  id: 'last7Days',
                  label: 'Last 7 days',
               },
               {
                  value: lastMonthRange,
                  id: 'last30Days',
                  label: 'Last month',
               },
               {
                  value: last6MonthRange,
                  id: 'last183Days',
                  label: 'Last 6 month',
               },
            ]}
         />

         <Text className="ml3" type="secondary">
            {formatDate(selectedDateFilter)} - {getCurrentDate()}
         </Text>
      </div>
   );
};

export default DateFilter;
