import React from 'react';
import { Funnel, FUNNEL_CONVERSATION_FIELD } from '@ant-design/plots';
import { Datum, FunnelConfig } from '@ant-design/charts';

type JourneyAnalysisProps = {
   stage: string;
   number: number;
};

const JourneyAnalysis = (props: JourneyAnalysisProps[]) => {
   const config = {
      data: props.sort(
         (a: { number: number }, b: { number: number }) => b.number - a.number
      ),
      xField: 'stage',
      yField: 'number',
      isTransposed: true,
      minSize: 0.3,
      maxSize: 0.9,
      label: {
         formatter: (datum) => {
            return `${datum.stage}: ${datum.number}`;
         },
      },
      conversionTag: {
         formatter: (datum: Datum) => {
            return (
               datum[FUNNEL_CONVERSATION_FIELD][1] /
               datum[FUNNEL_CONVERSATION_FIELD][0]
            ).toFixed(2);
         },
      },
      tooltip: {
         formatter: (datum) => {
            return {
               name: datum.stage,
               value: datum.number,
            };
         },
      },
   } as FunnelConfig;

   return <Funnel className="w-full" {...config} />;
};

export default JourneyAnalysis;
