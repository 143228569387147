import React, { useRef } from "react";
import { Cascader } from "antd";
import { useParams } from "react-router-dom";
import {
  Filter,
  FilterOption,
  FiltersResponse,
  SelectedFilter,
} from "../../types";
import style from "./campaign-filter.module.scss";
import { useQuery } from "@tanstack/react-query";
import { getCampaignFilters } from "../../api-calls/api-calls";

type CampaignFilterType = {
  label: string;
  filterType: string;
  value: string;
  children: CampaignFilterChild[];
};

type CampaignFilterChild = {
  value: string;
  label: string;
};

type SingleValueType = (string | number)[];

type CampaignFilterProps = {
  onFilterChange: (e: Filter[][]) => void;
};
const CampaignFilter = ({ onFilterChange }: CampaignFilterProps) => {
  // State to store the timer ID for debouncing
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);

  const debouncedOnChange = (value: SingleValueType[]) => {
    // Clear the previous timer if it exists
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }
    // Set a new timer to call the onFilterChange function after 1 second
    debounceTimerRef.current = setTimeout(() => {
      const lookupFilters: SelectedFilter = {};

      value.forEach((v: SingleValueType) => {
        const cmpf = campaignFilters.find(
          (cmpf: CampaignFilterType) => cmpf.label === v[0]
        );

        if (cmpf?.label && !lookupFilters[cmpf.label]) {
          lookupFilters[cmpf.label] = [];
        }

        if (cmpf && v.length === 1) {
          const parentFilter = findParentFilters(cmpf);
          lookupFilters[cmpf.label] =
            lookupFilters[cmpf.label].concat(parentFilter);
        } else {
          findOptionValueAndUpdateFilters(cmpf, v, lookupFilters);
        }
      });

      const newSelectedFilters = Object.values(lookupFilters);
      onFilterChange(newSelectedFilters);
    }, 1000);
  };

  const { campaignId } = useParams();

  const { data: campaignFilters } = useQuery(
    [campaignId],
    () => getCampaignFilters(campaignId as string),
    {
      staleTime: 10 * 60 * 1000,
      select: (res: any) =>
        res.map(
          ({
            filterLabel,
            filterOptionsValues,
            filterType,
          }: FiltersResponse) => ({
            filterType,
            label: filterLabel,
            value: filterLabel,
            children: filterOptionsValues.map(({ value }: FilterOption) => ({
              value,
              label: value,
            })),
          })
        ),
    }
  );

  const findParentFilters = (cmpf: CampaignFilterType): Filter[] => {
    const parentFilter: any = [];
    cmpf.children.forEach((fov: CampaignFilterChild) => {
      parentFilter.push({ filterType: cmpf.filterType, value: fov.value });
    });
    return parentFilter;
  };

  const findOptionValueAndUpdateFilters = (
    cmpf: CampaignFilterType | undefined,
    v: SingleValueType,
    lookupFilters: SelectedFilter
  ) => {
    const optionValue =
      cmpf &&
      cmpf.children.find((fov: CampaignFilterChild) => fov.value === v[1]);
    cmpf &&
      lookupFilters[cmpf.label].push({
        filterType: cmpf.filterType,
        value: optionValue?.value,
      } as Filter);
  };

  return (
    <div className={`${style["campaign-filter-wrapper"]} flex items-center`}>
      <div className="cascader-wrapper">
        <Cascader
          className="mr3"
          options={campaignFilters}
          onChange={debouncedOnChange}
          multiple
          allowClear={false}
        />
      </div>
    </div>
  );
};

export default CampaignFilter;
