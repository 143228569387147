import {Rate, theme, Typography} from "antd";
import React from "react";
import {ResponseAverageRatingSummaryProps} from "../../types";


const { useToken } = theme;

const AverageRatingSummary = ({ data }: ResponseAverageRatingSummaryProps) => {

    const { token } = useToken();

    const {Title, Text} = Typography


    return (
        <div className='flex flex-column items-center justify-center h-full'>
            <Title level={1} style={{ color: token.colorLink}}>
                {data?.avg?.toFixed(2)}
            </Title>
            <Rate allowHalf disabled defaultValue={0} value={data?.avg} style={{ fontSize: 74 }} />
            <Title className='mt4' level={5}>
                {data?.count}
            </Title>
            <Text type="secondary">
                Rating contributors
            </Text>
        </div>
    )
}

export default AverageRatingSummary;