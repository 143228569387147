import { api } from '../libs/api';
import { getCardDataApiUrl } from '../utils';
import { QueryFunctionContext } from '@tanstack/react-query';
import { Filter, ViewData } from '../types';

export const getCampaignById = async (campaignId: string) => {
   try {
      const { data } = await api.get(`/campaign/${campaignId}`);
      return data;
   } catch (reason) {
      console.error(
         `Error occured while fetching campaignId: ${campaignId}`,
         reason
      );
   }
};

export const getCampaignFilters = async (campaignId: string) => {
   try {
      const { data } = await api.get(
         `/response-analyzer/${campaignId}/filters`
      );
      return data;
   } catch (reason) {
      console.error(`Error occured while fetching campaign filters, `, reason);
   }
};

export const fetchResponseAnalyzerMetrics = async ({
   queryKey,
}: QueryFunctionContext<[Filter[][], string, ViewData, string]>) => {
   const [selectedFilters, campaignId, viewData, startFrom] = queryKey;

   const filtersQuery = encodeURIComponent(
      JSON.stringify(selectedFilters || [])
   );

   try {
      const { data } = await api.get(
         `${getCardDataApiUrl(
            viewData,
            campaignId
         )}?filters=${filtersQuery}&from=${startFrom}&responseLabel=${
            viewData.responseLabel
         }&action=${viewData.api.action}`
      );
      return data;
   } catch (reason) {
      console.error(`Error occured while fetching all campaigns`, reason);
   }
};
